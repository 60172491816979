import React, { useEffect, useState } from "react";
import { HiOutlinePlus, HiCheck, HiOutlineX, HiPencilAlt, HiOutlineTrash, HiRefresh, HiDownload } from "react-icons/hi";
import { toast } from "react-hot-toast";
import { QRCodeCanvas } from "qrcode.react";

import API from "../api";

const Laboratory = ({}) => {
  const [laboratories, setLaboratories] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedLaboratory, setSelectedLaboratory] = useState(null);

  const getData = async () => {
    try {
      const response = await API.get("/laboratory");
      if (!response.ok) return toast.error(response.message);
      setLaboratories(response.data);

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="px-4 pb-16">
      <div className="divide-y divide-gray-200 mt-2">
        <div className="bg-gray-50 grid grid-cols-10 px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
          <div className="col-span-3">Nom</div>
          <div className="col-span-3">Adresse</div>
          <div className="col-span-2">Téléphone</div>
          {/* <div className="col-span-2">ID Kalisil</div> */}
          <div className="col-span-1">ID HRPIM</div>
          <div className="col-span-1 flex justify-end">
            <button type="button" className="text-blue-500 text-lg" onClick={() => setOpenAddModal(true)}>
              <HiOutlinePlus />
            </button>
          </div>
        </div>
        <div className="bg-white divide-y divide-gray-200">
          {laboratories.map((laboratory) => (
            <div key={laboratory._id} className="grid grid-cols-10 items-center p-2">
              <div className="col-span-3">{laboratory.name}</div>
              <div className="col-span-3">{laboratory.address}</div>
              <div className="col-span-2">{laboratory.phone}</div>
              {/* <div className="col-span-2">{laboratory.kalisilId}</div> */}
              <div className="col-span-1">{laboratory.hrpimId}</div>
              <div className="col-span-1 flex justify-end">
                <button type="button" className="text-blue-500 text-lg" onClick={() => setSelectedLaboratory(laboratory)}>
                  <HiPencilAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {openAddModal && (
        <AddModal
          close={() => {
            setOpenAddModal(false);
            getData();
          }}
        />
      )}
      {selectedLaboratory && (
        <EditModal
          selectedLaboratory={selectedLaboratory}
          getData={async () => {
            const updatedLaboratories = await getData();
            const updatedSelectedLaboratory = updatedLaboratories.find((laboratory) => laboratory._id === selectedLaboratory._id);
            setSelectedLaboratory(updatedSelectedLaboratory);
          }}
          close={() => {
            setSelectedLaboratory(null);
            getData();
          }}
        />
      )}
    </div>
  );
};

const AddModal = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  // const [kalisilId, setKalisilId] = useState("");
  const [hrpimId, setHrpimId] = useState("");
  const [coordinates, setCoordinates] = useState([0, 0]);

  const addLaboratory = async () => {
    if (!name || !address) return toast.error("Veuillez remplir tous les champs obligatoires");

    try {
      setLoading(true);
      const response = await API.post("/laboratory", {
        name,
        address,
        phone,
        // kalisilId,
        hrpimId,
        location: {
          type: "Point",
          coordinates,
        },
      });

      if (!response.ok) return toast.error(response.message);

      toast.success("Laboratoire ajouté avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Ajouter un laboratoire</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <div className="mt-4 space-y-4">
              <div>
                <p className="text-sm font-semibold mb-1">Nom*</p>
                <input className="p-2 border rounded-md w-full" placeholder="Nom du laboratoire" value={name} onChange={(e) => setName(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Adresse*</p>
                <input className="p-2 border rounded-md w-full" placeholder="Adresse" value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Téléphone</p>
                <input className="p-2 border rounded-md w-full" placeholder="Téléphone" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>

              {/* <div>
                <p className="text-sm font-semibold mb-1">ID Kalisil</p>
                <input className="p-2 border rounded-md w-full" placeholder="ID Kalisil" value={kalisilId} onChange={(e) => setKalisilId(e.target.value)} />
              </div> */}

              <div>
                <p className="text-sm font-semibold mb-1">ID HRPIM</p>
                <input className="p-2 border rounded-md w-full" placeholder="ID HRPIM" value={hrpimId} onChange={(e) => setHrpimId(e.target.value)} />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-semibold mb-1">Latitude</p>
                  <input
                    type="number"
                    className="p-2 border rounded-md w-full"
                    placeholder="Latitude"
                    value={coordinates[1]}
                    onChange={(e) => setCoordinates([coordinates[0], parseFloat(e.target.value) || 0])}
                  />
                </div>
                <div>
                  <p className="text-sm font-semibold mb-1">Longitude</p>
                  <input
                    type="number"
                    className="p-2 border rounded-md w-full"
                    placeholder="Longitude"
                    value={coordinates[0]}
                    onChange={(e) => setCoordinates([parseFloat(e.target.value) || 0, coordinates[1]])}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={addLaboratory}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditModal = ({ selectedLaboratory, getData, close }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(selectedLaboratory.name);
  const [address, setAddress] = useState(selectedLaboratory.address);
  const [phone, setPhone] = useState(selectedLaboratory.phone || "");
  //   const [kalisilId, setKalisilId] = useState(selectedLaboratory.kalisilId || "");
  const [hrpimId, setHrpimId] = useState(selectedLaboratory.hrpimId || "");
  const [coordinates, setCoordinates] = useState(selectedLaboratory.location?.coordinates || [0, 0]);

  const updateLaboratory = async () => {
    if (!name || !address) return toast.error("Veuillez remplir tous les champs obligatoires");

    try {
      setLoading(true);
      const response = await API.put(`/laboratory/${selectedLaboratory._id}`, {
        name,
        address,
        phone,
        // kalisilId,
        hrpimId,
        location: {
          type: "Point",
          coordinates,
        },
      });

      if (!response.ok) return toast.error(response.message);

      toast.success("Laboratoire mis à jour avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const deleteLaboratory = async () => {
    const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer ce laboratoire ?");
    if (!confirm) return;

    try {
      setLoading(true);
      const response = await API.delete(`/laboratory/${selectedLaboratory._id}`);
      if (!response.ok) return toast.error(response.message);

      toast.success("Laboratoire supprimé avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
  };

  const regenerateQRCode = async () => {
    try {
      const confirm = window.confirm(
        "Attention : l'ancien QR Code sera automatiquement désactivé. Il faudra remplacer l'ensemble des anciens QR Codes présents dans le laboratoire par le nouveau. Voulez-vous continuer ?"
      );
      if (!confirm) return;
      setLoading(true);
      const response = await API.put(`/laboratory/${selectedLaboratory._id}/regenerate-qr`);
      if (!response.ok) return toast.error(response.message);
      await getData();
      toast.success("QR Code régénéré avec succès");
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code-canvas");
    if (!canvas) return toast.error("Il faut d'abord générer un QR Code");

    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = `QRCODE-${selectedLaboratory.name}.png`;
    link.href = url;
    link.click();
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Modifier un laboratoire</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <div className="mt-4 space-y-4">
              <div>
                <p className="text-sm font-semibold mb-1">Nom*</p>
                <input className="p-2 border rounded-md w-full" placeholder="Nom du laboratoire" value={name} onChange={(e) => setName(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Adresse*</p>
                <input className="p-2 border rounded-md w-full" placeholder="Adresse" value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Téléphone</p>
                <input className="p-2 border rounded-md w-full" placeholder="Téléphone" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>

              {/* <div>
                <p className="text-sm font-semibold mb-1">ID Kalisil</p>
                <input className="p-2 border rounded-md w-full" placeholder="ID Kalisil" value={kalisilId} onChange={(e) => setKalisilId(e.target.value)} />
              </div> */}

              <div>
                <p className="text-sm font-semibold mb-1">ID HRPIM</p>
                <input className="p-2 border rounded-md w-full" placeholder="ID HRPIM" value={hrpimId} onChange={(e) => setHrpimId(e.target.value)} />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-semibold mb-1">Latitude</p>
                  <input
                    type="number"
                    className="p-2 border rounded-md w-full"
                    placeholder="Latitude"
                    value={coordinates[1]}
                    onChange={(e) => setCoordinates([coordinates[0], parseFloat(e.target.value) || 0])}
                  />
                </div>
                <div>
                  <p className="text-sm font-semibold mb-1">Longitude</p>
                  <input
                    type="number"
                    className="p-2 border rounded-md w-full"
                    placeholder="Longitude"
                    value={coordinates[0]}
                    onChange={(e) => setCoordinates([parseFloat(e.target.value) || 0, coordinates[1]])}
                  />
                </div>
              </div>

              <div className="border rounded-md p-4">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-sm font-semibold">QR Code</p>
                  <div className="flex gap-2">
                    <button className="flex items-center text-blue-500 hover:text-blue-600" onClick={regenerateQRCode} disabled={loading}>
                      <HiRefresh className="mr-1" />
                      Régénérer
                    </button>
                    {selectedLaboratory.qrCodeToken && (
                      <button className="flex items-center text-blue-500 hover:text-blue-600" onClick={downloadQRCode}>
                        <HiDownload className="mr-1" />
                        Télécharger
                      </button>
                    )}
                  </div>
                </div>
                {selectedLaboratory.qrCodeToken && (
                  <div className="flex justify-center">
                    <QRCodeCanvas id="qr-code-canvas" value={selectedLaboratory.qrCodeToken} size={200} />
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-red-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={deleteLaboratory}
                disabled={loading}
              >
                <HiOutlineTrash className="mr-2 inline-block" />
                Supprimer
              </button>
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={updateLaboratory}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Laboratory;
