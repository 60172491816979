import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HiOutlinePlus, HiCheck, HiOutlineX, HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import { toast } from "react-hot-toast";

import API from "../api";

const Questions = ({}) => {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const getData = async () => {
    try {
      const response = await API.get("/question");
      if (!response.ok) return toast.error(response.message);
      setQuestions(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="px-4 pb-16">
      <div className="divide-y divide-gray-200 mt-2">
        <div className="bg-gray-50 grid grid-cols-12 px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
          <div className="col-span-2">Type</div>
          <div className="col-span-1">Code</div>
          <div className="col-span-3">Question</div>
          <div className="col-span-2">Codes déclencheurs</div>
          <div className="col-span-3">Options</div>
          <div className="col-span-1 flex justify-end">
            <button type="button" className="text-blue-500 text-lg" onClick={() => setOpenAddModal(true)}>
              <HiOutlinePlus />
            </button>
          </div>
        </div>
        <div className="bg-white divide-y divide-gray-200">
          {questions.map((question) => (
            <div key={question._id} className="grid grid-cols-12 items-center p-2">
              <div className="col-span-2">{question.type === "NOW" ? "Admission au laboratoire" : "Admission au domicile"}</div>
              <div className="col-span-1">{question.questionCode}</div>
              <div className="col-span-3">{question.question}</div>
              <div className="col-span-2">{question.triggerCodes?.join(", ")}</div>
              <div className="col-span-3">
                {question.options?.map((opt, i) => (
                  <div key={i}>
                    {opt.value} ({opt.code})
                  </div>
                ))}
              </div>
              <div className="col-span-1 flex justify-end">
                <button type="button" className="text-blue-500 text-lg" onClick={() => setSelectedQuestion(question)}>
                  <HiPencilAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {openAddModal && (
        <AddModal
          close={() => {
            setOpenAddModal(false);
            getData();
          }}
        />
      )}
      {selectedQuestion && (
        <EditModal
          selectedQuestion={selectedQuestion}
          close={() => {
            setSelectedQuestion(null);
            getData();
          }}
        />
      )}
    </div>
  );
};

const AddModal = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("NOW");
  const [questionCode, setQuestionCode] = useState("");
  const [question, setQuestion] = useState("");
  const [triggerCodes, setTriggerCodes] = useState("");
  const [options, setOptions] = useState([{ value: "", code: "" }]);

  const addQuestion = async () => {
    if (!question || !type) return toast.error("Veuillez remplir tous les champs obligatoires");

    try {
      setLoading(true);
      const triggerCodesArray = triggerCodes
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code !== "");

      const validOptions = options.filter((opt) => opt.value && opt.code);

      const response = await API.post("/question", { type, questionCode, question, triggerCodes: triggerCodesArray, options: validOptions });

      if (!response.ok) return toast.error(response.message);

      toast.success("Question ajoutée avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { value: "", code: "" }]);
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Ajouter une question</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <div className="mt-4 space-y-4">
              <div>
                <p className="text-sm font-semibold mb-1">Type*</p>
                <select className="p-2 border rounded-md w-full" value={type} onChange={(e) => setType(e.target.value)}>
                  <option value="NOW">Admission au laboratoire</option>
                  <option value="LATER">Admission au domicile</option>
                </select>
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Code de la question</p>
                <input className="p-2 border rounded-md w-full" placeholder="Code de la question" value={questionCode} onChange={(e) => setQuestionCode(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Question*</p>
                <input className="p-2 border rounded-md w-full" placeholder="Question" value={question} onChange={(e) => setQuestion(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Codes déclencheurs</p>
                <input
                  className="p-2 border rounded-md w-full"
                  placeholder="Séparer les codes par une virgule (,)"
                  value={triggerCodes}
                  onChange={(e) => setTriggerCodes(e.target.value)}
                />
              </div>

              <div>
                <div className="flex justify-between items-center mb-2">
                  <p className="text-sm font-semibold">Options</p>
                  <button type="button" className="text-blue-500 text-sm flex items-center" onClick={addOption}>
                    <HiOutlinePlus className="mr-1" /> Ajouter une option
                  </button>
                </div>
                {options.map((option, index) => (
                  <div key={index} className="flex space-x-2 mb-2">
                    <input
                      className="p-2 border rounded-md flex-1"
                      placeholder="Valeur"
                      value={option.value}
                      onChange={(e) => handleOptionChange(index, "value", e.target.value)}
                    />
                    <input className="p-2 border rounded-md flex-1" placeholder="Code" value={option.code} onChange={(e) => handleOptionChange(index, "code", e.target.value)} />
                    {options.length > 1 && (
                      <button type="button" className="text-red-500" onClick={() => removeOption(index)}>
                        <HiOutlineTrash />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={addQuestion}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditModal = ({ selectedQuestion, close }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(selectedQuestion.type);
  const [questionCode, setQuestionCode] = useState(selectedQuestion.questionCode || "");
  const [question, setQuestion] = useState(selectedQuestion.question);
  const [triggerCodes, setTriggerCodes] = useState(selectedQuestion.triggerCodes?.join(", ") || "");
  const [options, setOptions] = useState(selectedQuestion.options?.length ? selectedQuestion.options : [{ value: "", code: "" }]);

  const updateQuestion = async () => {
    if (!question || !type) return toast.error("Veuillez remplir tous les champs obligatoires");

    try {
      setLoading(true);
      const triggerCodesArray = triggerCodes
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code !== "");

      const validOptions = options.filter((opt) => opt.value && opt.code);

      const response = await API.put(`/question/${selectedQuestion._id}`, { type, questionCode, question, triggerCodes: triggerCodesArray, options: validOptions });

      if (!response.ok) return toast.error(response.message);

      toast.success("Question mise à jour avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const deleteQuestion = async () => {
    const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer cette question ?");
    if (!confirm) return;

    try {
      setLoading(true);
      const response = await API.delete(`/question/${selectedQuestion._id}`);
      if (!response.ok) return toast.error(response.message);

      toast.success("Question supprimée avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { value: "", code: "" }]);
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Modifier une question</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <div className="mt-4 space-y-4">
              <div>
                <p className="text-sm font-semibold mb-1">Type*</p>
                <select className="p-2 border rounded-md w-full" value={type} onChange={(e) => setType(e.target.value)}>
                  <option value="NOW">Admission au laboratoire</option>
                  <option value="LATER">Admission au domicile</option>
                </select>
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Code de la question</p>
                <input className="p-2 border rounded-md w-full" placeholder="Code de la question" value={questionCode} onChange={(e) => setQuestionCode(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Question*</p>
                <input className="p-2 border rounded-md w-full" placeholder="Question" value={question} onChange={(e) => setQuestion(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Codes déclencheurs</p>
                <input
                  className="p-2 border rounded-md w-full"
                  placeholder="Séparer les codes par une virgule (,)"
                  value={triggerCodes}
                  onChange={(e) => setTriggerCodes(e.target.value)}
                />
              </div>

              <div>
                <div className="flex justify-between items-center mb-2">
                  <p className="text-sm font-semibold">Options</p>
                  <button type="button" className="text-blue-500 text-sm flex items-center" onClick={addOption}>
                    <HiOutlinePlus className="mr-1" /> Ajouter une option
                  </button>
                </div>
                {options.map((option, index) => (
                  <div key={index} className="flex space-x-2 mb-2">
                    <input
                      className="p-2 border rounded-md flex-1"
                      placeholder="Valeur"
                      value={option.value}
                      onChange={(e) => handleOptionChange(index, "value", e.target.value)}
                    />
                    <input className="p-2 border rounded-md flex-1" placeholder="Code" value={option.code} onChange={(e) => handleOptionChange(index, "code", e.target.value)} />
                    {options.length > 1 && (
                      <button type="button" className="text-red-500" onClick={() => removeOption(index)}>
                        <HiOutlineTrash />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-red-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={deleteQuestion}
                disabled={loading}
              >
                <HiOutlineTrash className="mr-2 inline-block" />
                Supprimer
              </button>
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={updateQuestion}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
