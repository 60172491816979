import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HiOutlinePlus, HiCheck, HiOutlineX, HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import { toast } from "react-hot-toast";

import API from "../api";

const Guidelines = ({}) => {
  const history = useHistory();
  const [guidelines, setGuidelines] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState(null);

  const getData = async () => {
    try {
      const response = await API.get("/guideline");
      if (!response.ok) return toast.error(response.message);
      setGuidelines(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="px-4 pb-16">
      <div className="divide-y divide-gray-200 mt-2">
        <div className="bg-gray-50 grid grid-cols-12 px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
          <div className="col-span-4">Codes déclencheurs</div>
          <div className="col-span-7">Recommandation</div>
          <div className="col-span-1 flex justify-end">
            <button type="button" className="text-blue-500 text-lg" onClick={() => setOpenAddModal(true)}>
              <HiOutlinePlus />
            </button>
          </div>
        </div>
        <div className="bg-white divide-y divide-gray-200">
          {guidelines.map((guideline) => (
            <div key={guideline._id} className="grid grid-cols-12 items-center p-2">
              <div className="col-span-4">{guideline.triggerCodes?.join(", ")}</div>
              <div className="col-span-7">{guideline.guideline}</div>
              <div className="col-span-1 flex justify-end">
                <button type="button" className="text-blue-500 text-lg" onClick={() => setSelectedGuideline(guideline)}>
                  <HiPencilAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {openAddModal && (
        <AddModal
          close={() => {
            setOpenAddModal(false);
            getData();
          }}
        />
      )}
      {selectedGuideline && (
        <EditModal
          selectedGuideline={selectedGuideline}
          close={() => {
            setSelectedGuideline(null);
            getData();
          }}
        />
      )}
    </div>
  );
};

const AddModal = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [guideline, setGuideline] = useState("");
  const [triggerCodes, setTriggerCodes] = useState("");

  const addGuideline = async () => {
    if (!guideline) return toast.error("Veuillez remplir tous les champs obligatoires");

    try {
      setLoading(true);
      const triggerCodesArray = triggerCodes
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code !== "");

      const response = await API.post("/guideline", { guideline, triggerCodes: triggerCodesArray });

      if (!response.ok) return toast.error(response.message);

      toast.success("Recommandation ajoutée avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Ajouter une recommandation</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <div className="mt-4 space-y-4">
              <div>
                <p className="text-sm font-semibold mb-1">Recommandation*</p>
                <textarea className="p-2 border rounded-md w-full" rows={4} placeholder="Recommandation" value={guideline} onChange={(e) => setGuideline(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Codes déclencheurs</p>
                <input
                  className="p-2 border rounded-md w-full"
                  placeholder="Séparer les codes par une virgule (,)"
                  value={triggerCodes}
                  onChange={(e) => setTriggerCodes(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={addGuideline}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditModal = ({ selectedGuideline, close }) => {
  const [loading, setLoading] = useState(false);
  const [guideline, setGuideline] = useState(selectedGuideline.guideline);
  const [triggerCodes, setTriggerCodes] = useState(selectedGuideline.triggerCodes?.join(", ") || "");

  const updateGuideline = async () => {
    if (!guideline) return toast.error("Veuillez remplir tous les champs obligatoires");

    try {
      setLoading(true);
      const triggerCodesArray = triggerCodes
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code !== "");

      const response = await API.put(`/guideline/${selectedGuideline._id}`, { guideline, triggerCodes: triggerCodesArray });

      if (!response.ok) return toast.error(response.message);

      toast.success("Recommandation mise à jour avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const deleteGuideline = async () => {
    const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer cette recommandation ?");
    if (!confirm) return;

    try {
      setLoading(true);
      const response = await API.delete(`/guideline/${selectedGuideline._id}`);
      if (!response.ok) return toast.error(response.message);

      toast.success("Recommandation supprimée avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Modifier une recommandation</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <div className="mt-4 space-y-4">
              <div>
                <p className="text-sm font-semibold mb-1">Recommandation*</p>
                <textarea className="p-2 border rounded-md w-full" rows={4} placeholder="Recommandation" value={guideline} onChange={(e) => setGuideline(e.target.value)} />
              </div>

              <div>
                <p className="text-sm font-semibold mb-1">Codes déclencheurs</p>
                <input
                  className="p-2 border rounded-md w-full"
                  placeholder="Séparer les codes par une virgule (,)"
                  value={triggerCodes}
                  onChange={(e) => setTriggerCodes(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-red-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={deleteGuideline}
                disabled={loading}
              >
                <HiOutlineTrash className="mr-2 inline-block" />
                Supprimer
              </button>
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={updateGuideline}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guidelines;
